<template>
  <div class="container">
    <div class="banner-cont" v-if="bannerList.length > 0">
      <img :src="bannerList[0].bannerImg" />
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <!-- :class="{ 'vertical': item.displayType > 1, 'double': item.displayType === 3 }" -->
      <div class="good-item" v-for="(item, index) in prodList" :key="index"
        :class="{ 'vertical': item.displayType > 1, 'double': item.displayType === 3 }"
        @click="handleDetail(item.productID)">
        <div class="ivv-media" :class="{ 'unsold': item.selloutImg }">
          <img v-lazy="item.displayType === 2 ? item.displayTypeImg : item.productUrl" />
          <!-- <img v-lazy="item.productUrl" /> -->
          <div class="prompt" v-if="item.selloutImg">
            <img :src="item.selloutImg" />
          </div>
        </div>
        <div class="ivv-cont">
          <div class="good-name ellipsis2">{{ item.productName }}</div>
          <div class="introduce">
            <div class="good-format ellipsis2" v-if="item.productContent">{{ item.productContent }}</div>
            <div class="delivery" v-if="item.brandId === 4">{{ item.deliveryMethod === 1 ? '国内发货' : '澳洲直邮' }}</div>
          </div>
          <div class="good-format ellipsis2" v-if="item.productEffect">{{ item.productEffect }}</div>
          <div class="price-box">
            <span>￥</span>
            <span class="discount-price">{{ item.goodsPrice }}</span>
            <van-tag type="primary" round color="#FFB5BB">旗舰店主价</van-tag>
            <span class="original-price">￥{{ item.retailPrice }}</span>
          </div>
          <div class="sold-num">已订购{{ item.buyedCount }}件
            <van-button type="primary" color="#FF98A0" @click.stop="submit(item.productID)">立即抢购</van-button>
          </div>
        </div>
      </div>
    </van-list>
    <!--推荐人信息-->
    <van-popup v-model="showRecommendLayer" class="recommend-popup" :close-on-click-overlay="false"
      :close-on-popstate="true">
      <recommend-popup @onsure="onRecommendSure" />
    </van-popup>
  </div>
</template>

<script>
import { replaceAppToken, deliveryMessage } from "../../utils/appUtil";
import RecommendPopup from "../../components/RecommendPopup.vue";
export default {
  name: "UpgradeZone",
  components: { RecommendPopup },
  data() {
    return {
      loading: true,
      finished: false,
      prodList: [],
      brandId: 3,
      pageIndex: 1,
      pageSize: 10,
      agentInfo: null,
      bannerList: [],
      isInApp: false,
      referUserPhone: "",
      levelTitle: 1, //1新人 2超V 3一级 4省代 5大区
      userIdentityV4: -1, //新人-1 超V 0 代理1 取消授权2
      showRecommendLayer: false
    }
  },
  created() {
    let { token, inapp } = this.$route.query;
    if (token) {
      this.isInApp = true;
      replaceAppToken(token, () => {
        this.init();
      });
    } else {
      if (inapp === '1') this.isInApp = true;
      this.init();
    }

  },
  methods: {
    init() {
      this.getUserIdentityNew();
      this.getBannerList();
      this.getUserAgentInfo();

   
    },
    //获取用户身份信息
    getUserIdentityNew() {
      this.post("/TeamAgent/AgentInfo/HomeIdentity_V4", {}, 2).then(json => {
        if (json && json.code === 1) {
          //0：待审核，1：已审核， 3：未建立经销体系 4：黑名单，5：取消授权
          // userIdentityV4  新人-1 超V 0 代理1 取消授权2
          let { mzState, jkState, jkLevel, mzLevel } = json.response;
          if (this.brandId === 3) {
            if (mzLevel > 2) {
              this.userIdentityV4 = ([4, 5].indexOf(mzState) >= 0) ? 2 : 1;
            } else {
              this.userIdentityV4 = mzLevel === 2 ? 0 : -1;
            }
            this.levelTitle = mzLevel;
          } else {
            if (jkLevel > 2) {
              this.userIdentityV4 = ([4, 5].indexOf(jkState) >= 0) ? 2 : 1;
            } else {
              this.userIdentityV4 = (jkLevel === 2) ? 0 : -1;
            }
            this.levelTitle = jkLevel;
          }
        }
      });
    },
    getUserAgentInfo() {
      this.post("/GroupBuy/SubmitOrder/SettlementInfo", {
        brandId: this.brandId
      }, 2).then(json => {
        if (json && json.code === 1) {
          let data = json.response;
          this.agentInfo = data;
          this.onLoad();
        }
      });
    },
    //获取banner图
    getBannerList() {
      this.get("/OrderGoods/Product/GetJuhuiBannerList", {}, 2).then(res => {
        if (res.code === 1) {
          this.bannerList = res.response;
        }
      })
    },
    onLoad() {
      this.get("/OrderGoods/Product/GetJuhuiProductList", {
        brandId: this.brandId
      }, 2).then(res => {
        this.loading = false;
        this.finished = true;
        if (res.code === 1) {
          let list = res.response || [];
          let { CurrentLevelId } = this.agentInfo;
          //梵洁诗、大健康 一级省代大区  香蜜还没添加
          let levelList = [1, 2, 3, 23, 24, 25];
          list.forEach(item => {
            let priceList = item.levePirces;
            if (levelList.indexOf(CurrentLevelId) >= 0) {
              let it = priceList.find(tt => tt.LevelId === CurrentLevelId);
              item.goodsPrice = it ? it.priceAgo : priceList[priceList.length - 1].priceAgo
            } else {
              item.goodsPrice = priceList[2].priceAgo;
            }
          })
          this.prodList = this.prodList.concat(list);
        }
      }).catch(() => {
        this.loading = false;
        this.finished = true;
      });
    },
    //点击查看详情
    handleDetail(prodId) {
      this.$router.push("/upgradeZone/detail?goodId=" + prodId + "&brandId=" + this.brandId + "&inApp=" + (this.isInApp ? 1 : 0))
    },
    //立即抢购
    submit(prodId) {
      if (this.userIdentityV4 === 1) {
        this.$dialog.confirm({
          title: "提示",
          message: "您已经是旗舰店主，请前往“采购订货”购买活动套餐哦！",
          confirmButtonText: "立即前往",
          confirmButtonColor: '#ff98a0'
        }).then(() => {
          if (this.isInApp) {
            deliveryMessage({ 'action': 'jumpStore', data: {} });
            return
          }
          window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/groupon_list.html?brandId=" + this.brandId;
        }).catch(() => { })
        return;
      }
      window.location.href = this.baseHost + "/#/upgradeZoneConfirm?goodId=" + prodId + "&num=1" + "&referUserPhone=" + this.referUserPhone + "&brandId=" + this.brandId + "&inApp=" + (this.isInApp ? 1 : 0)
    },
    //确认推荐人手机号
    onRecommendSure(e) {
      this.referUserPhone = e;
      this.showRecommendLayer = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-cont {
  img {
    width: 100%;
  }
}

.van-list {
  display: flex;
  padding: 0 10px;
  align-items: flex-start;
  flex-wrap: wrap;

  ::v-deep .van-list__finished-text {
    width: 100%;
  }

  ::v-deep .van-list__loading {
    width: 100%;
  }
}

.good-item {
  display: flex;
  width: 100%;
  padding: 11px 10px;
  align-items: center;
  position: relative;
  text-align: left;
  flex-wrap: wrap;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #fff 80%, rgba(0, 0, 0, 0.04));

  .ivv-media {
    margin-right: 5px;

    img {
      width: 120px;
    }
  }

  .ivv-cont {
    flex: 1;
    position: relative;

    .good-name {
      font-size: 14px;
      color: #636363;
      font-weight: bold;
      margin-bottom: 2px;
      height: 40px;
    }

    .good-format {
      color: #999999;
      margin-bottom: 5px;
    }

    .introduce {
      display: flex;

      .good-format {
        flex: 1;
        color: #636363;
      }

      .delivery {
        color: #ffb5bb;
        font-size: 9px;
        padding: 2px 10px 2px 5px;
        margin-left: 10px;
        line-height: 12px;
        position: relative;
        box-sizing: border-box;
        height: 18px;
        border: 1px solid #ff98a1;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          border-top: 8px solid transparent;
          border-right: 8px solid #ff98a1;
          border-bottom: 8px solid transparent;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: -1px;
          border-top: 8px solid transparent;
          border-right: 8px solid #fff;
          border-bottom: 8px solid transparent;
        }
      }
    }

    .price-box {
      padding: 5px 0;

      .discount-price {
        font-size: 16px;
      }

      .van-tag {
        padding: 2px 4px;
        font-size: 12px;
        display: inline-block;
        transform: scale(0.75);
      }

      .original-price {
        color: #999999;
        font-size: 12px;
        text-decoration: line-through;
      }

      .count {
        color: #999;
        float: right;
      }
    }

    .in-stock {
      color: #999999;
      font-size: 10px;
    }

    .sold-num {
      color: #B5B5B5;
      font-size: 12px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .van-button {
        // position: absolute;
        // top: 0;
        // right: 0;
        width: 68px;
        height: 24px;
        padding: 0;
        font-size: 12px;
        line-height: 24px;
      }
    }
  }

  .unsold {
    position: relative;

    .prompt {
      position: absolute;
      top: calc(50% - 30px);
      left: calc(50% - 30px);
      width: 60px;
      height: 60px;
      z-index: 2;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 120px;
      height: 120px;
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  &.vertical {
    background: linear-gradient(to bottom, #fff 92%, rgba(0, 0, 0, 0.04));

    .ivv-media {
      width: 100%;
      margin-right: 0;

      img {
        width: 100%;
      }
    }
  }

  &.double {
    width: 50%;
    display: inline-block;
    background: none;
  }
}

::v-deep .recommend-popup {
  background-color: transparent;
}
</style>